import axios from 'axios';
import { API_ENDPOINT } from '../config/api-config';


export async function getData(api) {
    
    const response = await axios({
        method: 'GET',
        url: `${API_ENDPOINT}/${api}`,
    });
    
    if(response.status === 200){
        return response.data
    } else{
        throw new Error(`Error calling REST api ${response.status}`);
    }

            
}


export async function postConvertUrl(url) {

    let data = {
        "url" : url
    };

    const response = await axios({
        method: 'POST',
        url: `${API_ENDPOINT}/links?action=convert`,
        data: data
    });
    
    if(response.status === 200){
        return response.data
    } else{
        throw new Error(`Error calling REST api ${response.status}`);
    }

}
