import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { TextField, Switch, FormGroup, FormControlLabel } from '@material-ui/core';
import { CircularProgress, Grid, List, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useCookies } from "react-cookie";
import axios from 'axios';
import AppListItem from '../components/AppListItem';

import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { API_ENDPOINT } from '../config/api-config';
import { getData, postConvertUrl } from '../services/browseintelapi';
import { EventType } from '@azure/msal-browser';

const useStyles = makeStyles((theme) => ({
  
  al: {
    width: '0px'
  },
  
  root: {
    width: '100%'
    
  },
  favSwitch: {
    flexDirection: "row",
    display: "flex"
  }
}));

export default function Apps(props) {
  const { instance } = props.useMsal();

  const classes = useStyles();
  const [response, setResponse] = useState({ status: '', message: '', apps: [] });
  const [isLoading, setIsLoading] = useState(false);
  const [filteredApps, setFilteredApps] = React.useState([]);
  const [filterText, setFilterText] = React.useState('');
  const [cookies] = useCookies();
  const isAuthenticated = props.useIsAuthenticated();

  const [favsOnly, setFavsOnly] = React.useState(false);
  const handleChange = (event) => {
    setFavsOnly(event.target.checked);
    filterApps(filterText, event.target.checked);
  };

  // for various reasons, this is the only way to get links open in ios with the noreferrer
  // noreferrer needed to support "modern javascript" rewrite
  // ios doesn't let you open a new tab (_blank) unless its a user driven action or popup blocker disabled
  const altopenUrl = async (targetURL) => {
    try {
      let convertedUrl = await postConvertUrl(targetURL);
      window.open(convertedUrl.urlEncoded,"_self", "noreferrer")
    }
    catch(e) {
      setResponse({ status: 'error', message: `Error converting Url.` });
    }

}

  const filterApps = (filterText, favsOnly) => {
    let apps = response.apps.filter(app =>
      (app.name?.toLowerCase().indexOf(filterText?.toLowerCase()) !== -1)
      || (app.url?.toLowerCase().indexOf(filterText?.toLowerCase()) !== -1)) || [];

    if (favsOnly) {
      apps = apps.filter(app => app.favorite);
    }

    setFilteredApps(apps);
  }

  useEffect(() => {
    const fetchApps = async () => {
      setIsLoading(true);
        
      getData('applications/favorites').then(resp => {
        if (resp) {
          setResponse({ status: 'success', apps: resp });
          setFilteredApps(resp);
          setIsLoading(false);
        }
      }).catch(err =>{
        setResponse({ status: 'error', message: `Error fetching applications. ${err.message}` });
        setIsLoading(false);
      });
     
      
    }

    if(!isAuthenticated){
      instance.addEventCallback((message)=>{
        if (message.eventType === EventType.LOGIN_SUCCESS) {
          const payload = message.payload;
          if (!payload.account) return;

          instance.setActiveAccount(payload.account);
          fetchApps();
      }
      })
    }else{
      fetchApps();
    }

  }, [cookies.user, instance, isAuthenticated]);

  const updateApp = (appForUpdate) => {
    response.apps.forEach((item, index, items) => {
      if (item.id === appForUpdate.id) {
        items[index] = appForUpdate;
      }
    });
    setResponse({ apps: response.apps });
    filterApps(filterText, favsOnly);
  }

  const handleToggleFav = async (app) => {
    try {
      if (!app.favorite) {
        //const jsonBody = { idsid: cookies.user, decoratedApp: app }; 
        const resp = await axios({
          method: 'POST',
          url: `${API_ENDPOINT}/favorites/${app.id}`,
          //withCredentials: true,
          //data: jsonBody
        });
        
        if (resp.status === 200) {
          app.favorite = resp.data.id;
          updateApp(app);
        }
      }
      else {
        const resp = await axios({
          method: 'DELETE',
          url: `${API_ENDPOINT}/favorites/${app.id}`,
          //withCredentials: true,
          //data: { idsid: cookies.user, decoratedApp: app }
        });
        if (resp.status === 200) {
          delete app.favorite;
          updateApp(app);
        }
      }
    }
    catch (err) {
      setResponse({ ...response.apps, status: 'warning', message: `Error ${app.favorite ? 'removing' : 'adding'} favorite. ${err.message}` });
    }

  }

  return (
    <div className={classes.root}>
      {(response.status === 'warning') && <Alert severity="warning" style={{marginBottom: '1rem'}}> {response.message}</Alert>}
      {(response.status === 'error') && <Alert severity="error" style={{marginBottom: '1rem'}}>{response.message}</Alert>}


      <div className={classes.root}>
        {/* <h2 style={{ paddingBottom: '1rem' }}> Applications </h2> */}
        {isLoading ?
          <div className="spinner"> <CircularProgress /> </div>
          :
          <Grid container spacing={2} direction="row" >
            <Grid item xs={12}>
              <TextField
                id="filter-apps"
                data-testid="filter-apps-testid"
                options={response.apps}
                placeholder="App Name or URL (Enter will open URL)"
                // getOptionLabel={(option) => option.url}
                style={{ width: '100%', paddingRight: '10px' }}
                variant="outlined"
                // renderInput={(params) => <TextField  {...params} variant="outlined" label="Filter Apps or Type Url"
                onChange={(ev) => { filterApps(ev.target.value, favsOnly); setFilterText(ev.target.value); }}
                onKeyPress={  (e) => {
                  if (e.key === 'Enter') {
                    let url = filterText;
                    url = url.trim();
                    if (url.toLowerCase().startsWith("http://")){
                      url = url.split("://");
                      url = "https://"+url[1];
                    }
                    if (!url.toLowerCase().startsWith("https://")){
                      url = "https://"+url;
                    }
                    altopenUrl(url);
                    
                  }
                }}


                // autoFocus fullWidth />}
              />


            </Grid>
            <Grid item xs={12}>
              <FormGroup className={classes.favSwitch} >
                <FormControlLabel
                  control={
                    <Switch
                      checked={favsOnly}
                      onChange={handleChange}
                      name="checkedFav"
                      color="primary"
                    />
                  }
                  label={<Typography style={{ fontSize: 16, fontWeight: 'bold' }}>Show Favorites Only
                   </Typography>}
                  labelPlacement="start"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} className={classes.al} >
              <List  >
                {filteredApps?.map(app => (
                  app &&
                  <div key={app.id}  >
                    <AppListItem app={app} handleToggleFav={handleToggleFav} />
                  </div>
                ))}
              </List>
            </Grid>
          </Grid>
        }
      </div>
    </div>
  );
}

Apps.defaultProps = { useIsAuthenticated, useMsal };

