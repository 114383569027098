import { LogLevel } from "@azure/msal-browser";
import { UI_AZURE_CLIENT_ID, API_AZURE_CLIENT_ID } from "./api-config";

// Config object to be passed to Msal on creation



export const msalConfig = {
    auth: {
        clientId:  UI_AZURE_CLIENT_ID,
        authority: 'https://login.microsoftonline.com/46c98d88-e344-4ed4-8496-4ed7712e255d',
        redirectUri: window.location.origin //'http://localhost:3000/'
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default: return;
                }
            },
            logLevel: LogLevel.Warning
        }
    }
};


// Add here scopes for id token to be used at MS Identity Platform endpoints.
// export const graphApiLoginRequest = {
//     //scopes: ["api://68bd6ca7-1ef8-4d06-ba18-1c437d134fd5/Poc.Read","User.Read"]
//     scopes: ["User.Read"]
// };

export const loginRequest = {
    scopes: ["User.Read"]
    //scopes: ["User.Read"]
};

export const allScopesRequest = {
    scopes: ["User.Read", `api://${API_AZURE_CLIENT_ID}/BrowseIntel.AllUser`, `api://${API_AZURE_CLIENT_ID}/BrowseIntel.Admin`]
    //scopes: ["User.Read"]
};


export const customApiScopeRequest = {
    //scopes: ["api://68bd6ca7-1ef8-4d06-ba18-1c437d134fd5/Poc.Read", "api://68bd6ca7-1ef8-4d06-ba18-1c437d134fd5/Admin.Write"]
    //scopes: ["api://68bd6ca7-1ef8-4d06-ba18-1c437d134fd5/BrowseIntel.AllUser", "api://68bd6ca7-1ef8-4d06-ba18-1c437d134fd5/BrowseIntel.Admin"]
    scopes: [`api://${API_AZURE_CLIENT_ID}/BrowseIntel.AllUser`, `api://${API_AZURE_CLIENT_ID}/BrowseIntel.Admin`]
    //scopes: ["User.Read"]
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};