import { useMsal } from "@azure/msal-react";
import axios from "axios";
import { customApiScopeRequest } from "./config/msal.config";


export const AxiosProvider = () =>{

    const { instance, accounts } = useMsal();

    axios.interceptors.request.use(async function (config) {
        // Do something before request is sent
        //console.log(config);

        let tokenResponse = await instance.acquireTokenSilent({
          ...customApiScopeRequest,
          account: accounts[0]
        })
        config.headers['Authorization'] = `Bearer ${tokenResponse.accessToken}`
        return config;
      }, function (error) {
        // Do something with request error
        return Promise.reject(error);
      });
    return (
        <div></div>
    );
}