import configurator from '@intc/configurator';

// defaults
configurator.register({
  hostname: ['browse-intel-dev.intel.com', 'browse-intel-ui-dev.apps1-fm-int.icloud.intel.com'],
  production: false,
  title: 'Browse Intel (Dev)',
  azureLogin: true
});

// local values
configurator.register({
  hostname: [/localhost/],
  title: 'Browse Intel (Local)',
  azureLogin: true
});

// test values
configurator.register({
  hostname: ['browse-intel-ui-test.apps1-fm-int.icloud.intel.com'],
  production: false,
  title: 'Browse Intel (Test)',
  azureLogin: false
});

// production values
configurator.register({
  hostname: ['browse-intel.intel.com', 'browse-intel-ui.apps1-fm-int.icloud.intel.com'],
  title: 'Browse Intel',
  production: true,
  azureLogin: false
});

export default configurator.config;
