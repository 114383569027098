import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { DialogContent } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  title: {
    backgroundColor: '#0095CA',
    color: theme.palette.common.white, //'white'
  }
}));

export default function PopupDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  return (

    <Dialog className={classes.root} onClose={handleClose} aria-labelledby="simple-dialog" open={open} maxWidth='md'>
      <DialogTitle className={classes.title} id="simple-dialog">Profile Info</DialogTitle>
      <DialogContent>
        {props.element}
      </DialogContent>

    </Dialog>
  );
}

PopupDialog.propTypes = {
  element: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

