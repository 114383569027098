import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useAccount } from "@azure/msal-react";
import { loginRequest, graphConfig, allScopesRequest } from "../config/msal.config";
import { queryGraph } from "../services/graph.js";

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import EmailIcon from '@material-ui/icons/Email';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import IconButton from '@material-ui/core/IconButton';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Tooltip } from "@material-ui/core";

import PopupDialog from './PopupDialog';

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:hover': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.secondary.main,
        },
    },
}))(MenuItem);


const ProfileData = (props) => {
    return (
        <div id="profile-div">
            <p><strong>Name: </strong> {props.profileData.displayName}</p>
            <p><strong>Title: </strong> {props.profileData.jobTitle}</p>
            <p><strong>Mail: </strong> {props.profileData.mail}</p>
            <p><strong>Phone: </strong> {props.profileData.businessPhones[0]}</p>
            <p><strong>Location: </strong> {props.profileData.officeLocation}</p>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    svgIcon: {
        '&:hover': {
            color: theme.palette.common.white,
        },
    },
}));

export default function AzureSignInButton() {
    const classes = useStyles();

    const { instance, accounts } = useMsal();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const account = useAccount(accounts[0] || {});
    const [profileData, setProfileData] = useState(null);
    // const [userRoles, setUserRoles] = useState(null);

    useEffect(() => {
        const fetchUserProfile = async () => {
            instance.acquireTokenSilent({
                ...loginRequest,
                account: account
            }).then(async (response) => {
                const profile = await queryGraph(graphConfig.graphMeEndpoint, response.accessToken);
                setProfileData(profile)
            })
            .catch(async (error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    // fallback to interaction when silent call fails
                    return instance.acquireTokenRedirect(loginRequest)
                }
            })
            .catch(error => {
                // handleError(error);
            });

            
        };
        if (!profileData) fetchUserProfile();
    });

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [open, setOpen] = React.useState(false);

    const showProfile = () => {
        setAnchorEl(null);
        setOpen(true);
    };

    const handlePopupClose = (value) => {
        setOpen(false);
    };

    return (
        <>
            <AuthenticatedTemplate>
                <div>
                    <Tooltip title={account && account.name} placement="left">
                        <IconButton aria-haspopup="true" onClick={handleClick} aria-label="login-user">
                            <AccountCircleIcon style={{ color: 'white', fontSize: 32 }} />
                        </IconButton>
                    </Tooltip>
                    <StyledMenu
                        id="user-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <StyledMenuItem onClick={showProfile}>
                            <ListItemIcon classes={{root: classes.svgIcon}}>
                                <ContactPhoneIcon  fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Profile" />
                        </StyledMenuItem>
                        <PopupDialog fullWidth element={<ProfileData profileData={profileData} />} open={open} onClose={handlePopupClose} />

                        <StyledMenuItem onClick={handleClose}>
                            <ListItemIcon classes={{root: classes.svgIcon}}>
                                <EmailIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Email Us" />
                        </StyledMenuItem>
                        <StyledMenuItem onClick={() => instance.logout()}>
                            <ListItemIcon classes={{root: classes.svgIcon}}>
                                <ExitToAppIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Logout" />
                        </StyledMenuItem>
                    </StyledMenu>
                </div>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Button onClick={() => instance.loginRedirect(allScopesRequest)}>Sign In</Button>
            </UnauthenticatedTemplate>
        </>
    );
};