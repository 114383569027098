import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText, Divider, Link } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    nested: {
        paddingLeft: theme.spacing(4)
    },
    question: {
        marginTop: '1rem',
        fontWeight: 'bold'
    }
}));

export default function Apps() {
    const classes = useStyles();

    return (
        <div>
            <div className={classes.root}>
                <h2 style={{ paddingBottom: '1rem' }}> Help </h2>
                <List
                    component="nav"
                    className={classes.root}>
                    <ListItem>
                        <ListItemText classes={{ primary: classes.question }} >
                            How can I properly setup my mobile device for my work at Intel?
                        </ListItemText>
                    </ListItem>
                    <ListItemText  className={classes.nested} >
                        Go to the <Link href="https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIxNjExNTIwNDA5NiJ9/all">Mobile Device Yammer community</Link> and check out the pinned items.
                    </ListItemText>
                    <Divider />   

                    <ListItem>
                        <ListItemText classes={{ primary: classes.question }} >
                                Where can I get support for vendor apps such as Outlook, Teams, Firstup, Workday? 
                        </ListItemText>
                    </ListItem>
                    <ListItemText  className={classes.nested} >
                        Contact the support team for that app at Intel. Or you can try the <Link href="https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIxNjExNTIwNDA5NiJ9/all">Mobile Device Yammer community</Link>.
                    </ListItemText>
                    <Divider />   

                    <ListItem>
                        <ListItemText classes={{ primary: classes.question }} >
                            Where can I get support for Intel Enterprise Mobile apps? 
                        </ListItemText>
                    </ListItem>
                    <ListItemText  className={classes.nested} >
                        Contact that application’s support team first. Or you can try the Enterprise Mobile Apps <Link href="https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIyMzA4NTc3Njg5NiJ9/all">Enterprise Mobile Apps Yammer community</Link>.
                    </ListItemText>
                    <Divider />   

                    <ListItem>
                        <ListItemText classes={{ primary: classes.question }} >
                            How can I enable my app to be available on mobile devices for Intel employees?  
                        </ListItemText>
                    </ListItem>
                    <ListItemText  className={classes.nested} >
                        Go to the <Link href="https://intel.sharepoint.com/sites/EnterpriseMobileApps">Enterprise Mobile apps SharePoint site </Link> and check out “How to engage for mobile app services”.
                    </ListItemText>
                    <Divider />  

                    <ListItem>
                        <ListItemText classes={{ primary: classes.question }} >
                            How can I get support for a production issue about my Mobile Device/App?   
                        </ListItemText>
                    </ListItem>
                    <ListItemText  className={classes.nested} >
                        Call TAC and use fastpath 998.
                    </ListItemText>
                    <Divider />          
                </List>
            </div>
        </div>
    );
}