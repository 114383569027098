import React, { useEffect } from 'react';
import { Route, MemoryRouter } from 'react-router';
import { Routes } from "react-router-dom";

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { makeStyles } from '@material-ui/core/styles';
import { Drawer, Hidden, CssBaseline } from '@material-ui/core';
import { AppBar, Toolbar, List } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Typography, Divider, IconButton } from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';
import AppsIcon from '@material-ui/icons/Apps';
import HelpIcon from '@material-ui/icons/Help';
import HomeIcon from '@material-ui/icons/Home';

import { Link as RouterLink } from 'react-router-dom';

import logo from '../images/logo-white.svg';
import AzureSignInButton from './AzureSignInButton';
import DrawerMenuItem from './DrawerMenuItem';

import config from '../config/configurator.config';
import Apps from '../pages/Apps';
import Help from '../pages/Help';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { allScopesRequest } from '../config/msal.config';

const drawerWidth = 180;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  title: {
    flexGrow: 1,
    fontSize: 20,
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    [theme.breakpoints.down('sm')]: {
      width: `100%`,
      marginLeft: drawerWidth,
    },

  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  logoIcon: {
    marginTop: theme.spacing(2.5),
    width: '50px', height: '20px',
    marginLeft: theme.spacing(1),
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerHeader: {
    display: 'flex',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    // justifyContent: 'flex-end',
  },
  toolbar: {
    ...theme.mixins.toolbar,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#262626'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    marginTop: '64px',
  }
}));

export default function SideDrawer(props) {
  const classes = useStyles();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDrawerClose = () => {
    setMobileOpen(false);
  };

  const appBar = (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar >
        <IconButton variant="contained"
          className={classes.menuButton} edge="start" color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerToggle}
        >
          <MenuIcon style={{ fontSize: 30 }} />
        </IconButton>

        <Typography className={classes.title} noWrap>
          {config.title}
        </Typography>
        <AzureSignInButton /> 
        {/* {config.azureLogin? <AzureSignInButton /> : <IssoSignInButton /> } */}
      </Toolbar>
    </AppBar>
  );

  const drawer = (
    <div>
      <div className={'App-header col'} style={{height: mobileOpen? '56px': '64px'}} >
        <div>
          <img className={classes.logoIcon} src={logo} alt="Logo" />
          {mobileOpen &&
            <IconButton style={{ color: 'white', float: 'right' }} onClick={handleDrawerToggle}>
              <ChevronLeftIcon style={{ fontSize: 32, marginRight: '-1rem' }} />
            </IconButton>
          }
        </div>
      </div>
      <div>
        <List>
          <DrawerMenuItem to="/apps" primary='Applications' icon={<AppsIcon />} clickHandler={handleDrawerClose}/>
        </List>
        <Divider style={{backgroundColor: 'gray'}} />
        <List>
          <DrawerMenuItem to="/help" primary='Help' icon={<HelpIcon />} clickHandler={handleDrawerClose}/>
        </List>
      </div>
    </div>
  );

  const NoMatch = () => (
    <center style={{ paddingTop: "2em" }}>
      <Alert severity="warning">Oops!!! Page requested not found.</Alert>
      <h4> <RouterLink to="/apps" style={{fontSize: 16}} > Back to Home </RouterLink> </h4>
    </center>
  )

  const container = window !== undefined ? () => window().document.body : undefined;

  const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    useEffect( () => {
      async function checkAuth() {
        if (!isAuthenticated && inProgress === InteractionStatus.None) {
            await instance.loginRedirect(allScopesRequest);
        }
      }
      checkAuth();
    });

  return (

    <MemoryRouter initialEntries={['/apps']} initialIndex={0}>
      <div className={classes.root}>
        <CssBaseline />
        {appBar}

        <nav className={classes.drawer} aria-label="app-menu">
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor='left'
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer open variant="permanent"
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <div className={classes.content}>
          <Routes>
            <Route exact path="/apps" element={<Apps />} />
            <Route exact path="/help" element={<Help />} />
            <Route component={NoMatch} />
          </Routes>
        </div>
      </div>
    </MemoryRouter>

  );
}
