import React from "react";
import { ThemeProvider } from '@material-ui/core/styles';
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./config/msal.config";

import './App.scss';
import {dlux} from './dlux';
import SideDrawer from "./components/SideDrawer";
import { AxiosProvider } from "./AxiosProvider";

export default function App() {

  // Unused for now. Commented for code coverage. Uncomment when Azure auth is enabled
  // if (config.azureLogin) {
    const msalInstance = new PublicClientApplication(msalConfig);
    return (
      <MsalProvider instance={msalInstance}>
        <AxiosProvider></AxiosProvider>
        <ThemeProvider theme={dlux}>
            <SideDrawer />
          </ThemeProvider>
      </MsalProvider>
    );
  // }
  // else {
    // return (
    //   <CookiesProvider>
    //     <ThemeProvider theme={dlux}>
    //       <SideDrawer />
    //     </ThemeProvider>
    //     </CookiesProvider>
    // );
  // }

}

