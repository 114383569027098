import React from 'react';
import PropTypes from 'prop-types';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link as RouterLink } from 'react-router-dom';


DrawerMenuItem.propTypes = {
  icon: PropTypes.element,
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default function DrawerMenuItem(props) {
  const { icon, primary, to, clickHandler } = props;

  const renderLink = React.useMemo(
    () =>  React.forwardRef((itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />),
    [to],
  );

  return (
    <li>
      <ListItem button onClick={clickHandler} component={renderLink}>
        {icon ? <ListItemIcon style={{ minWidth: '36px', color: 'white' }}> {icon}</ListItemIcon> : null}
        <ListItemText primary={primary} style={{color:"white"}} />
      </ListItem>
    </li>
  );
}