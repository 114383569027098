import { createTheme } from '@material-ui/core/styles';

export const dlux = createTheme({
    palette: {
      primary: {
        main: '#0068B5',
      },
      secondary: {
        main: '#00C7FD',
      },
    },
    typography: {
      fontFamily: ["Intel Clear", "Roboto", "Helvetica", "Arial", "sans-serif"].join(','),
      fontSize: 12,
      button: {
        backgroundColor: '#0068B5'
      }
    },
    overrides: {
        MuiButton: {
          text: {
            border: 0,
            color: 'white',
            backgroundColor: '#0068B5',
            "&:hover": {
              backgroundColor: "#00C7FD"
            }
          },

        },
        MuiToolbar: {
          gutters: {
          paddingLeft: '15px',
          paddingRight: '10px',
          }
        },
    }
});