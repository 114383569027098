import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { ListItem, ListItemText, ListItemSecondaryAction} from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Avatar, ListItemAvatar } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
// import Link from '@material-ui/core/Link';
import StarsIcon from '@material-ui/icons/Stars';
import genericImage from '../images/generic.png';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },
    appAvatar: {
        width: theme.spacing(6),
        height: theme.spacing(6),
    },
    listItemTitle: {
        display: 'inline',
        marginBottom: theme.spacing(1),
        fontWeight: 'bold'
    },
    listItemText: {
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.spacing(300),
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          },
                  
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.spacing(150),
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          },
      
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.spacing(50),
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          },
    },
    listItem: {
        [theme.breakpoints.down('md')]: {
            height: theme.spacing(13),
          },
        height: theme.spacing(13),
    }
}));

AppListItem.propTypes = {
    app: PropTypes.object,
    handleToggleFav: PropTypes.func
};

export default function AppListItem({ app, handleToggleFav }) {
    const classes = useStyles();
    // const windowFeatures = `toolbar=0,scrollbars=1,status=1,resizable=0,location=1,menuBar=0,width=${600},height=${400},top=${100},left=${100}`;

    return (

        <ListItem alignItems="flex-start" divider classes={{root: classes.listItem}}>
            <ListItemAvatar style={{paddingRight: '1rem'}}>
              <Avatar variant="square" className={classes.appAvatar} src={app.image? app.image : genericImage} />
            </ListItemAvatar>
            <ListItemText style={{paddingRight: '.5rem'}} disableTypography
                // iOS and "modern javascript" rewrite compatibility
                onClick={() => window.open(app.urlObf, '_self', 'noreferrer')}
                primary={<Typography className={classes.listItemTitle} variant="subtitle1" color="textPrimary"> {app.name}</Typography>}
                // primaryTypographyProps={classes.listItemTitle}
                secondary={
                    <React.Fragment>
                        <Grid container spacing={2}>
                            <Grid item xs={10}>
                                <Typography
                                    variant="body1"
                                    className={classes.listItemText}
                                    color="textPrimary"
                                >
                                    {app.description}
                                </Typography>
                            </Grid>
                            {/* <Grid item xs={10}>
                                <Typography variant="body1" className={classes.inline} color="textPrimary" xs={10} md={8} lg={6}>
                                    <Link href="#" onClick={() => window.open(app.urlObf, '_self')}>
                                        {app.urlObf}
                                    </Link>                 
                                </Typography>
                            </Grid> */}
                        </Grid>
                    </React.Fragment>
                }
            />
            <ListItemSecondaryAction>
                <StarsIcon data-testid="starsicon" style={{ color: app.favorite ? "#0068B5": "lightGray", fontSize: 40 }} onClick={() => handleToggleFav(app)}/>
            </ListItemSecondaryAction>
        </ListItem>


    );
}