import { API_ENDPOINT } from '../config/api-config';

export async function queryGraph(graphEndpoint, accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(graphEndpoint, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}


export async function loadPoc(accessToken, api) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(`${API_ENDPOINT}/${api}`, options)
        .then(response => {

            if(!response.ok){
                return response.status + ':' + response.statusText;
            }
            return response.json()
        })
        .catch(error => console.log(error));
}
